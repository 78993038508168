import React from "react";
import ListaProtocolli from "../protocollo/listaProtocolli";
import Tipologie from "../tipologie/listaTipologie";
import DettaglioProtocollo from "../protocollo/dettaglioProtocollo";
import NuovoProtocollo from "../protocollo/nuovoProtocollo";

export const routes = {
    "*/annotatio-proto/": () => <ListaProtocolli />,
    "*/annotatio-proto-dett/": () => <DettaglioProtocollo />,
    "*/annotatio-proto-ins/": () => <NuovoProtocollo />,
    "*/annotatio-proto-tipo/": () => <Tipologie />,
  };