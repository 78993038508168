import React from "react";
import axios from 'axios';
import SelectTipoMovimento from '../tipologie/selectTipologieMovimento'
import Swal from "sweetalert2";
import '../util/config'
import { useSelector, useDispatch } from "react-redux";
import serverInfo from "../util/globals";
import { useState } from "react";
import {navigate} from 'hookrouter'
import { setprotocolloselezionato } from "../STORE/reducers/gestoreProtocolli";

export default function NuovoProtocollo(props) {

	const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
	const token = useSelector(state => state.gestoreUtente.token);

	const dispatch = useDispatch();
	
	const [id, setid] = useState(0)
	const [tipo , settipo]	 = useState('E')
	const [descrizione, setdescrizione]	 = useState('')
	const [idtipomovimento, setidtipomovimento] = useState('')
	const [oggetto, setoggetto] = useState('')
	const [ datascadenza, setdatascadenza] = useState('')
	const[dataarrivo, setdataarrivo] = useState(new Date().getFullYear()+"-"+(("0" + (new Date().getMonth() + 1)).slice(-2))+"-"+("0" + new Date().getDate()).slice(-2))

	const [ visible, setvisible] = useState(false)
	const [messaggio, setmessaggio] = useState('');
	const [file, setfile] = useState(null);

	function updateTipo(tipoSelezionato) {
		var tipoMovimento1 = idtipomovimento
		tipoMovimento1 = tipoSelezionato;
		setidtipomovimento(tipoMovimento1)
		setvisible(true)
	  }
	  
	function handleSubmit(event) {
		  event.preventDefault();
	    const formData = new FormData();
	    formData.append('id', id);
	    formData.append('descrizione',descrizione);
		formData.append('tipo', tipo);
		formData.append('idtipomovimento', idtipomovimento);
		formData.append('oggetto', oggetto);
		formData.append('datascadenza', datascadenza);
		formData.append('dataarrivo', dataarrivo);
		formData.append('website', global.config.domain);
		formData.append('email',email);
		formData.append('palestra',palestra.id);
		formData.append('file',file)
		formData.append('lic','000');
      axios.post(serverInfo.namesec+'/proto/inserisciProto', 
      		formData, {
				headers: {
				  Accept: 'application/json',
				  'Content-Type': 'multipart/form-data',
				  'Access-Control-Allow-Origin': '*',
				  'Access-Control-Allow-Methods': 'POST',
				  'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				  'Access-Control-Allow-Credentials': 'true',
				  'Authorization' :'Bearer '+token
				},
			  },
      )
        .then((result) => {
		const Toast = Swal.mixin({
			  toast: true,
			  position: 'bottom-end',
			  showConfirmButton: false,
			  timer: 3000
			});
			Toast.fire({
			  type: 'success',
			  title: result.data
			})
         setvisible(false);
		 dispatch(setprotocolloselezionato(result.data));
		 navigate('/annotatio-proto-dett/');
        });
    }

	function onChangeFile(e) {
		setfile(e.target.files[0])
	  }

	return (
      <div className="container-fluid">
        <h4>Inserimento nuovo protocollo</h4>
		<button className="btn btn-primary" onClick={() => navigate('/annotatio-proto/')}>Ricerca Protocollo</button>
		<form method="POST">
      		<div className="row">
				<div className="col-md-4">
					<div className="form-group">
						<label>Data arrivo</label>
						<input className="form-control" name="dataarrivo" type="date" value={dataarrivo} onChange={(e) => setdataarrivo(e.target.value)} />
					</div>
				</div>
				<div className="col-md-4">
						<div className="form-group">
							<label>Tipo (E/U)</label>
							<select className="form-control" name="tipo" value={tipo} onChange={(e) => settipo(e.target.value)}>
								<option value="">--tipo protocollo--</option>
								<option value="E">Entrata</option>
								<option value="U">Uscita</option>
							</select>
						</div>
				</div>
				<div className="col-md-4">
					<div className="form-group">
							<SelectTipoMovimento updateTipo={updateTipo} selected={idtipomovimento} />
						</div>
				</div>
				</div>
				<div className="row">
					<div className="col-md-4">
						<div className="form-group">
							<label>Oggetto</label>
							<input className="form-control" maxLength="50" name="oggetto" type="text" 
							value={oggetto} onChange={(e) => setoggetto(e.target.value)} />
						</div>
					</div>
					<div className="col-md-4">
					<div className="form-group">
							<label>Data scadenza</label>
							<input className="form-control" name="datascadenza" type="date" value={datascadenza} onChange={(e) => setdatascadenza(e.target.value)} />
						</div>
					</div>
				</div>
			<div className="row">
				<div className="col">
					<div className="form-group">
						<label>Descrizione</label>
						<textarea className="form-control" name="descrizione" type="text" value={descrizione} 
							onChange={(e) => setdescrizione(e.target.value)}></textarea>
					</div>
				</div>
			</div>
      <div className="row">
        <button type="button" className="btn btn-primary" onClick={handleSubmit}>Salva</button>
      </div>
	  </form>
    </div>
	);
}