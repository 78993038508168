import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import store from './STORE/store';
import AppRoute from './AppRoute';
import LoadMainThings from './util/LoadMainThings';
import PalestreAssociateSelect from './palestre/PalestreAssociateSelect';
import Tipologie from './tipologie/listaTipologie'
import DettaglioProtocollo from './protocollo/dettaglioProtocollo';
import ProtoFromUrl from './protocollo/dettaglioProtoFromUrl';

var elem = document.getElementById("protocolli");
if (elem){
  const email=elem.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
  }
  const key=elem.getAttribute("key");
  localStorage.setItem('key',key);

  const nome=elem.getAttribute("first_name");
    const cognome=elem.getAttribute("last_name");
    const telefono=elem.getAttribute("telefono");
  
    const tkn=elem.getAttribute("t");
    window.$t=tkn;

  ReactDOM.render(
    <Provider store={store}>
        <AppRoute email={email} nome={nome} cognome={cognome} telefono={telefono} tkn={tkn} />
    </Provider>,
    elem
  );
}

var tipoproto = document.getElementById("tipoproto");
if (tipoproto){
  const email=tipoproto.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
  }
  const key=tipoproto.getAttribute("key");
  localStorage.setItem('key',key);

  const nome=tipoproto.getAttribute("first_name");
    const cognome=tipoproto.getAttribute("last_name");
    const telefono=tipoproto.getAttribute("telefono");

  ReactDOM.render(
    <Provider store={store}>
      <LoadMainThings email={email} nome={nome} cognome={cognome} telefono={telefono} />
        <Tipologie email={email}/>
    </Provider>,
    tipoproto
  );
}

var dettproto = document.getElementById("dettproto");
if (dettproto){
  const email=dettproto.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
  }
  const key=dettproto.getAttribute("key");
  localStorage.setItem('key',key);

  ReactDOM.render(
    <Provider store={store}>
      <LoadMainThings email={email} />
        <ProtoFromUrl email={email} />
    </Provider>,
    dettproto
  );
}

/************ */
//LISTA PALESTRE ASSOCIATE
/************ */
var attivitaassociate=document.getElementById("attivitaassociate");
if (attivitaassociate){
  const email=attivitaassociate.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
  }  
  const key=attivitaassociate.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=attivitaassociate.getAttribute("linkannotatio");

  ReactDOM.render(
      <Provider store={store}>
        <LoadMainThings email={email} />
        <PalestreAssociateSelect email={email} link={linkannotatio} />
      </Provider>
      ,
      attivitaassociate
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
