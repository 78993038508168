import React from "react";
import axios from 'axios';
import SelectTipoMovimento from '../tipologie/selectTipologieMovimento'
import Swal from "sweetalert2";
import '../util/config'
import { useSelector } from "react-redux";
import { useState } from "react";
import serverInfo from "../util/globals";
import { useEffect } from "react";
import {navigate} from 'hookrouter'
import { inviaemailproto } from "../DAO/protocolloDAO";

export default function DettaglioProtocollo(props) {

	const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
	const token = useSelector(state => state.gestoreUtente.token);
	const protoselezionato = useSelector(state => state.gestoreProtocolli.protocolloselezionato);

	const [id, setid] = useState(protoselezionato.id);
	const [tipo, settipo] = useState(protoselezionato.tipo);
	const [descrizione, setdescrizione] = useState(protoselezionato.descrizione);
	const [idtipomovimento, setidtipomovimento] = useState(protoselezionato.idtipomovimento);
	const [oggetto, setoggetto] = useState(protoselezionato.oggetto);
	const [datascadenza, setdatascadenza] = useState(protoselezionato.datascadenza);
	const [dataarrivo, setdataarrivo] = useState(protoselezionato.dataarrivo);
	const [numero, setnumero] = useState(protoselezionato.numero);
	const [anno, setanno] = useState(protoselezionato.anno);
	const [visible, setvisible] = useState(false);
	const [file, setfile] = useState(null);
	const [foto, setfoto] = useState(null);
	const [files, setfiles] = useState([]);
	const [messaggio, setmessaggio] = useState('');
	const [emailperproto, setemailperproto] = useState(email)
	const [showemailperproto, setshowemailperproto] = useState(false)

	function handleMostraFile(){
	    const formData = new FormData();
		formData.append('id', id);
		formData.append('website', global.config.domain);
		formData.append('email',email);
		formData.append('lic','000');
		formData.append('palestra',palestra.id);
      	axios.post(serverInfo.namesec+'/proto/caricaFilesProtocolloRest/'+id, 
      		formData,
			  {
				headers: {
				  Accept: 'application/json',
				  'Content-Type': 'multipart/form-data',
				  'Access-Control-Allow-Origin': '*',
				  'Access-Control-Allow-Methods': 'POST',
				  'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				  'Access-Control-Allow-Credentials': 'true',
				  'Authorization' :'Bearer '+token
				},
			  },
      )
        .then((result) => {
        setfiles(result.data);
        }).catch(err => {
			alert("Caricamento file: "+err.response.data.message);
		});
	  }

	function salvaFoto(event) {
		event.preventDefault();

	    const formData = new FormData();
	    formData.append('id', id);
		formData.append('is',foto);
		formData.append('website', global.config.domain);
		formData.append('email',email);
		formData.append('lic','000');
		formData.append('palestra',palestra.id);
      	axios.post(serverInfo.namesec+'/proto/salvaFotoProtocolloRest/'+id, 
      		formData,
			  {
				headers: {
				  Accept: 'application/json',
				  'Content-Type': 'multipart/form-data',
				  'Access-Control-Allow-Origin': '*',
				  'Access-Control-Allow-Methods': 'POST',
				  'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				  'Access-Control-Allow-Credentials': 'true',
				  'Authorization' :'Bearer '+token
				},
			  },
      )
        .then((result) => {
        setmessaggio(result.data);
		const Toast = Swal.mixin({
			  toast: true,
			  position: 'top-end',
			  showConfirmButton: false,
			  timer: 3000
			});
			Toast.fire({
			  type: 'success',
			  title: result.data.nomefile
			})

		  setvisible(false);
		  handleMostraFile();
        }).catch(err => {
			alert("Salvataggio foto: "+err.response.data.message);
		});
	}

	useEffect(() =>{
		handleMostraFile();
		// document.getElementById('imgId').src = null;
	},[protoselezionato.id])

	function updateTipo(tipoSelezionato) {
		var tipoMovimento1 = idtipomovimento
		tipoMovimento1 = tipoSelezionato;
		setidtipomovimento(tipoMovimento1);
		setvisible(true)
	  }

	  

	function onChangeFile(e) {
		setfile(e.target.files[0])
	  }

	function handleUploadFile(event) {
		event.preventDefault();
	   
		const config = {
			headers: {
				'content-type': 'multipart/form-data',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST',
				'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				'Access-Control-Allow-Credentials': 'true',
				'Authorization' :'Bearer '+token
			},
		}
	    const formData = new FormData();
	    formData.append('id', id);
		formData.append('file',file)
		formData.append('website', global.config.domain);
		formData.append('email',email);
		formData.append('lic','000');
		formData.append('palestra',palestra.id);
      	axios.post(serverInfo.namesec+'/proto/salvaFileProtocolloRest/'+id, 
      		formData, config
      )
        .then((result) => {
        setmessaggio(result.data);
		const Toast = Swal.mixin({
			  toast: true,
			  position: 'top-end',
			  showConfirmButton: false,
			  timer: 3000
			});
			Toast.fire({
			  type: 'success',
			  title: result.data.nomefile
			})

		  setvisible(false);
		  handleMostraFile();
        }).catch(err => {
			alert("Salva file: "+err.response.data.message);
		});
	}

	function handleEliminaFile(event) {
		event.preventDefault();
	    const formData = new FormData();
		formData.append('id', event.target.id);
		formData.append('website', global.config.domain);
		formData.append('email',email);
		formData.append('lic','000');
		formData.append('palestra',palestra.id);
      	axios.post(serverInfo.namesec+'/proto/eliminaFile/'+event.target.id, 
      		formData,
			  {
				headers: {
				  Accept: 'application/json',
				  'Content-Type': 'multipart/form-data',
				  'Access-Control-Allow-Origin': '*',
				  'Access-Control-Allow-Methods': 'POST',
				  'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				  'Access-Control-Allow-Credentials': 'true',
				  'Authorization' :'Bearer '+token
				},
			  },)
        .then((result) => {
        setmessaggio(result.data);
		const Toast = Swal.mixin({
			  toast: true,
			  position: 'top-end',
			  showConfirmButton: false,
			  timer: 3000
			});
			Toast.fire({
			  type: 'success',
			  title: result.data.nomefile+" eliminato"
			})

		  setvisible(false)
		  setfile(null);
		  handleMostraFile();
        }).catch(err => {
			alert("Elimina file: "+err.response.data.message);
		});
    }

	function handleSubmit(event) {
		  event.preventDefault();
	    const formData = new FormData();
	    formData.append('id', id);
	    formData.append('descrizione',descrizione);
		formData.append('tipo', tipo);
		formData.append('idtipomovimento', idtipomovimento);
		formData.append('oggetto', oggetto);
		formData.append('datascadenza', datascadenza);
		formData.append('dataarrivo', dataarrivo);
		formData.append('anno', anno);
		formData.append('numero', numero);
		formData.append('website', global.config.domain);
		formData.append('email',email);
		formData.append('lic','000');
		formData.append('palestra',palestra.id);
    axios.post(serverInfo.namesec+'/proto/salvaProtocollo/'+id, formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+token
      },
    },  )
      .then((result) => {
      setmessaggio(result.data);
		const Toast = Swal.mixin({
			  toast: true,
			  position: 'top-end',
			  showConfirmButton: false,
			  timer: 3000
			});
			Toast.fire({
			  type: 'success',
			  title: result.data
			})
        setvisible(false);
      }).catch(err => {
		alert("Salvataggio protocollo: "+err.response.data.message);
	});
  }

	function elimina() {
		  if (window.confirm('Sei sicuro di voler cancellare il protocollo selezionato?')){
			const formData = new FormData();
			formData.append('website', global.config.domain);
			formData.append('email',email);
		formData.append('lic','000');
		formData.append('palestra',palestra.id);
			  axios.post(serverInfo.namesec+'/proto/eliminaProto/'+id, formData,
			  {
				headers: {
				  Accept: 'application/json',
				  'Content-Type': 'multipart/form-data',
				  'Access-Control-Allow-Origin': '*',
				  'Access-Control-Allow-Methods': 'POST',
				  'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				  'Access-Control-Allow-Credentials': 'true',
				  'Authorization' :'Bearer '+token
				},
			  },)
			        .then((result) => {
				         const Toast = Swal.mixin({
						  toast: true,
						  position: 'top-end',
						  showConfirmButton: false,
						  timer: 3000
						});
						Toast.fire({
						  type: 'success',
						  title: result.data
						})

			          
			          setvisible(false);
			        }).catch(err => {
						alert("Cancellazione protocollo: "+err.response.data.message);
					});
			}
	  }

	  function emailproto(){
		async function inviamemail(){
			const result=await inviaemailproto(emailperproto,palestra.id,id,token);
			alert(result)
		}
		inviamemail()
	  }


		var style1={
				color:"black"
		}
	    return (
      <div className="container-fluid">
		<div className="row">
			<h4>Protocollo {numero}-{anno}</h4>
			<button className="btn" onClick={() => navigate('/annotatio-proto/')}>Ricerca</button>&nbsp;
			<button className="btn" onClick={() => navigate('/annotatio-proto-ins/')}>Nuovo Protocollo</button>
		</div>
		<form method="POST">
			<div className="row">
				<div className="col">
					<div className="form-group">
						<label>Data arrivo</label>
						<input className="form-control" name="dataarrivo" type="date" value={dataarrivo} onChange={(e) => setdataarrivo(e.target.value)} />
					</div>
				</div>
				<div className="col">
					<div className="form-group">
							<label>Tipo (E/U)</label>
							<select className="form-control" name="tipo" value={tipo} onChange={(e) => settipo(e.target.value)}>
						    <option value="">--tipo protocollo--</option>
							<option value="E" selected={tipo==='E'?'selected':null}>Entrata</option>
							<option value="U" selected={tipo==='U'?'selected':null}>Uscita</option>
						</select>
						</div>
				</div>
			</div>
            <div className="row">
                <div className="col">
					<div className="form-group">
						<label>Oggetto</label>
						<input className="form-control" maxLength="50" style={{width: "100%"}} name="oggetto" type="text" value={oggetto} onChange={(e) => setoggetto(e.target.value)} />
					</div>
				</div>
				<div className="col">
					<div className="form-group">
							<SelectTipoMovimento updateTipo={updateTipo} selected={idtipomovimento} />
						</div>
				</div>
				<div className="col">
						<div className="form-group">
							<label>Data scadenza</label>
							<input className="form-control" name="datascadenza" type="date" value={datascadenza} onChange={(e) => setdatascadenza(e.target.value)} />
						</div>
				</div>
			</div>
			<div className="row">
				<div className="form-group">
					<label>Descrizione</label>
					<textarea className="form-control" rows={10} cols={100} name="descrizione" type="text" value={descrizione} onChange={(e) => setdescrizione(e.target.value)}></textarea>
				</div>
			</div>
		</form>
        <div className="row">
				{files&&Array.isArray(files)?
					(files.map(item =>(
						<div key={item.id}>
							<a href={serverInfo.namesec+"/wp/scaricaFile/"+item.id}>{item.nomeOriginale} ({item.dimensione}K)</a> | 
							<a type="button" className="btn btn-danger" onClick={handleEliminaFile} id={item.id}>Elimina</a>
						</div>
					)
					)
					)
				
				:""
				}
		</div>
        <div className="row">
				<input type="file" onChange={onChangeFile} />
				{file?
				<a type="button" className="btn btn-primary" onClick={handleUploadFile} style={style1}>Salva FILE</a>		
				:null}
		</div>
      <div className="modal-footer">
        <button type="button" className="btn btn-primary" onClick={handleSubmit}>Salva</button>

		<button type="button" className="btn btn-primary" onClick={()=>setshowemailperproto(!showemailperproto)}>Invia per email</button>

		{showemailperproto&&
			<div>
				<input type="email" name="emailperproto" value={emailperproto} onChange={(e)=> setemailperproto(e.target.value)} />
				<button type="button" className="btn btn-primary" onClick={emailproto}>Invia email</button>
			</div>
		}

		
        <div className="col">
					<a type="button" className="btn btn-danger" onClick={elimina} style={style1}>Elimina protocollo</a>
					</div>
      </div>
    </div>
	    );
	}