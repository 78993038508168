import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import gettoken from "../DAO/tokenDAO";
import { setpalestraassociata } from "../STORE/reducers/gestoreUtente";
import '../util/config'

export default function PalestraAssociataSelect(props) {
  const [palselected, setpalselected] = useState(global.localstorage.palestrasocioSelezionata)
  const [keytorefresh, setkeytorefresh] = useState(new Date().getTime())
  const palestrasocio = useSelector(state => state.gestoreUtente.palestraassociata);
  const email = useSelector(state => state.gestoreUtente.emailloggato);

  const dispatch=useDispatch();

  useEffect(() => {
    
  },[palestrasocio, keytorefresh])

    function handleChange() {
        localStorage.setItem('palselezionata', JSON.stringify(props.soc.palestra))
        localStorage.setItem('palestrasocioSelezionata', JSON.stringify(props.soc))
        dispatch(setpalestraassociata(props.soc))
        setpalselected(props.soc);
        props.setkeytorefresh(new Date().getTime())
        async function gett(){
          const result = await gettoken(email, props.soc.palestra.id, props.soc.palestra.website+props.soc.palestra.subdomain);
          console.log(result)
          window.$t = result
        }
        gett();
        // localStorage.removeItem('objanagrafica')
        // localStorage.removeItem('palassociata')
        // localStorage.setItem('palselezionata',this.state.soc.id)
        // localStorage.setItem('palestracalendario',this.state.soc.id)
        // localStorage.setItem('palestra',this.state.soc.palestra)
        // localStorage.setItem('orario',this.state.soc.orarioapertura1+'___'+this.state.soc.orariochiusura1+'___'+this.state.soc.orarioapertura2+'___'+this.state.soc.orariochiusura2)
        // localStorage.setItem('descrizioneattivita',this.state.soc.descrizioneattivita)
        // localStorage.setItem('logo',this.state.soc.logo)
        // localStorage.setItem('code','SOCIO-'+localStorage.getItem('utente'))
        if (props.link){
          window.location.href=props.link;
        }
      }

      return (
        props.soc&&
          <div >
            <a href="#" onClick={handleChange}>{props.soc.palestra.palestra} ({props.soc.amministratore?"Amministratore":"Collaboratore"})</a>
            {palestrasocio&&palestrasocio.palestra&&palestrasocio.palestra.id===props.soc.palestra.id&&
              <img alt="selected" src="https://annotatio.online/wp-content/uploads/2022/07/checkedGreen.png" style={{width:"20px"}} />
            }
          </div>
        );
  }