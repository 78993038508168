import { useRoutes, A } from "hookrouter";
import { useDispatch } from "react-redux";
import { sett } from "./STORE/reducers/gestoreUtente";
import LoadMainThings from "./util/LoadMainThings";
import Menu from "./util/menu";
import { routes } from "./util/routes";

const showDebugInfo=localStorage.getItem('debug')?true:false
function AppRoute(props) {
    const routeResult = useRoutes(routes);
    const dispatch = useDispatch();

  return (
    <div className="App">
      <Menu />
      <LoadMainThings 
      tkn={props.tkn}
      showDebugInfo={showDebugInfo} email={props.email} nome={props.nome} cognome={props.cognome} telefono={props.telefono} />
      {routeResult}
    </div>
  );
}

export default AppRoute;
