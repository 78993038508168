import {createSlice} from '@reduxjs/toolkit';

export const gestoreUtenteSlice = createSlice({
  name: 'gestoreUtente',
  initialState: {
    emailloggato: localStorage.getItem('email'),
    anagrafica: {},
    anagrafiche: [],
    utente: {},
    palestraAnagrafica: {},
    idpalestra: 0,
    palestraUtente: {},
    palestreassociate:[],
    palestraassociata:{}, //socio
    fp:'',
    token:''
  },
  reducers: {
    setanagrafica: (state, anagrafica) => {
      console.log('setanagraficaaaaaaaa ',anagrafica.payload)
      state.anagrafica =  null;
      state.anagrafica =  anagrafica.payload;
    },
    setanagraficheassociate: (state, anagrafiche) => {
      state.anagrafiche = anagrafiche.payload;
    },
    setpalestreassociate: (state, palestre) => {
      state.palestreassociate = palestre.payload;
    },
    setpalestraassociata: (state, palestra) => {
      state.palestraassociata = palestra.payload;
      console.log("palestraassociata ",state.palestraassociata)
    },
    setPalestraAnagrafica: (state, palestra) => {
      //console.log('setanagrafica ' + palestra.payload.id);
      state.palestraAnagrafica = palestra.payload;
    },
    setIdpalestra: (state, idpalestra) => {
      //console.log('setIdpalestra ' + idpalestra.payload);
      state.idpalestra = idpalestra.payload;
    },
    setutente: (state, utente) => {
      //console.log('setutente ', + utente.payload);
      state.utente = utente.payload;
    },
    setEmailLoggato: (state, email) => {
      //console.log('setEmailLoggato ' + email.payload);
      state.emailloggato = email.payload;
    },
    setfp: (state, fp ) => {
      //console.log('setdeviceinfo ' + fp.payload);
      state.fp = fp.payload;
    },
    settoken: (state, token ) => {
      //console.log('setdeviceinfo ' + fp.payload);
      state.token = token.payload;
    },
  },
});

export const {setanagrafica, setPalestraAnagrafica, setEmailLoggato, setanagraficheassociate, setIdpalestra, setfp, setpalestreassociate, setpalestraassociata, setutente, 
cleananagrafica, settoken} = gestoreUtenteSlice.actions;

export default gestoreUtenteSlice.reducer;
