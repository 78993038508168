import axios from 'axios';
import '../util/config'
import serverInfo from '../util/globals';

export default async function gettoken(email, idpalestra, appid){
  const formData = new FormData();
  formData.append('website', serverInfo.domain);
  formData.append('email', email);
  formData.append('appid', 'pluginproto');
  const response = await axios.post(serverInfo.namesec+ '/wp/token',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
      },
    },
  );
  return response.data;
}