import {configureStore} from '@reduxjs/toolkit';
import gestoreUtentiReducer from './reducers/gestoreUtente';
import gestoreProtocolliReducer from './reducers/gestoreProtocolli';
import gestoreTipiProdottoReducer from './reducers/gestoreTipiProdotto';
import gestoreCarrelloReducer from './reducers/gestoreCarrello';
import gestoreCalendariReducer from './reducers/gestoreCalendari';

export default configureStore({
  reducer: {
    gestoreUtente: gestoreUtentiReducer,
    gestoreTipiProdotto: gestoreTipiProdottoReducer,
    gestoreCarrello: gestoreCarrelloReducer,
    gestoreCalendari: gestoreCalendariReducer,
    gestoreProtocolli: gestoreProtocolliReducer,
  },
});
