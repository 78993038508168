import React from "react";
import {navigate} from 'hookrouter'
import Intestazione from "./Intestazione";

export default function Menu (props) {


    return (
<nav className="navbar navbar-expand-lg navbar-dark bg-primary">
  <Intestazione />
  
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="true" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">        <ul className="navbar-nav">
          <li className="nav-item">
            <a className={"nav-link "+(props.active==='protocolli'?'active':'')} onClick={() => navigate('/annotatio-proto/')}>Protocolli</a>
          </li>
					<li  className="nav-item"><a className={"nav-link "+(props.active==='tipo'?'active':'')}  onClick={() => navigate('/annotatio-proto-tipo/')}>Tipologie di Protocollo</a></li>
        </ul>
          </div>
          <a className="navbar-brand" href="#">Annotatio-PROTO</a>
</nav>
    );
}