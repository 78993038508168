import React, { useState } from "react";
import RicercaProtocolli from './ricercaProtocolli'
import Protocollo from './protocollo'
import {navigate} from 'hookrouter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons'
import { useSelector } from "react-redux";

export default function ListaProtocolli(props){
	const email = useSelector(state => state.gestoreUtente.emailloggato);
	const token = useSelector(state => state.gestoreUtente.toek);
	const protocolli = useSelector(state => state.gestoreProtocolli.protocollicercati)

      return (
		<span>
		{email?
    		<div className="container-fluid">

			<RicercaProtocolli />	
			
			<table className="table">
				<thead>
					<tr>
						<th></th>
						<th>Num. </th>
						<th>Data arrivo</th>
						<th>E/U</th>
						<th>Oggetto</th>
						<th>Tipologia</th>
						<th>Scadenza</th>
						<th>Allegati</th>
					</tr>
				</thead>
				<tbody>
    		{
    			protocolli&&Array.isArray(protocolli)&&protocolli.length>=0&& protocolli.map(item =>(
    					<Protocollo key={item.id} selected={item} />
    					)
    			)
    		}
			</tbody>
			</table>

			</div>
			:<span>Per accedere a questa pagina è necessario autenticarsi. </span>}
    </span>
    )
}