import React from "react";
import axios from 'axios';
import '../util/config'
import NuovaTipologia from './nuovaTipologia'
import Tipologia from './tipologia'
import Menu from "../util/menu";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons'
import { connect } from "react-redux";
import serverInfo from "../util/globals";

class Tipologie extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
    		tipologie:[]
    };
    this.refresh = this.refresh.bind(this);
	this.togglePopup=this.togglePopup.bind(this);
}

togglePopup(){
  this.setState({popupVisible: !this.state.popupVisible})
  }
  
  componentDidMount(){
	if (this.props.palestra){
		const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email',this.props.email);
		formData.append('palestra',this.props.palestra.id);
		formData.append('lic','000');
		axios.post(serverInfo.namesec+"/conto/getTipologieWp",formData,
		{
		headers: {
		  Accept: 'application/json',
		  'Content-Type': 'multipart/form-data',
		  'Access-Control-Allow-Origin': '*',
		  'Access-Control-Allow-Methods': 'POST',
		  'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		  'Access-Control-Allow-Credentials': 'true',
		  'Authorization' :'Bearer '+this.props.token
		},
		},)
		.then(response=>{
			this.setState({tipologie: response.data});
			console.log(response);
		})
		.catch(err => {
			alert("lista tipologie: "+err.response.data.message);
		});
	}
  }

  refresh(){
	const formData = new FormData();
	formData.append('website', global.config.domain);
	formData.append('email',this.props.email);
		formData.append('palestra',this.props.palestra.id);
		formData.append('lic','000');
	  axios.post(serverInfo.namesec+"/conto/getTipologieWp",formData,
	  {
		headers: {
		  Accept: 'application/json',
		  'Content-Type': 'multipart/form-data',
		  'Access-Control-Allow-Origin': '*',
		  'Access-Control-Allow-Methods': 'POST',
		  'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		  'Access-Control-Allow-Credentials': 'true',
		  'Authorization' :'Bearer '+this.props.token
		},
		},)
	  .then(response=>{
		  this.setState({tipologie:[]});
		  this.setState({tipologie: response.data});
		  console.log(response);
		  
	  }).catch(err => {
		alert("lista tipologie: "+err.response.data.message);
	});
  }
  componentDidCatch(error, info) {
	    // You can also log the error to an error reporting service
	    console.log(error, info);
	  }
  


  render() {
    return (
		<div className="container">
			{this.state.popupVisible?
				<div className="row">
			<NuovaTipologia refresh={this.refresh} togglePopup={this.togglePopup}/>
				</div>
				:null}
			<a type="button" className="btn btn-info btn-lg" onClick={this.togglePopup}>
				<FontAwesomeIcon icon={faPlusCircle} />
			</a>
			<h3>Lista tipologie Protocollo</h3>
    		{
    			this.state.tipologie&&Array.isArray(this.state.tipologie)&&this.state.tipologie.length>0?
    			this.state.tipologie.map(item =>(
    					<Tipologia key={item.codice} selected={item}
    					onUpdate={item.codice} refresh={this.refresh}  />
    					)
				)
				:<span>Nessuna tipologia configurata</span>
				
    		}
    		</div>
    );
  }
}

const mapStateToProps = state => {
	return {
		palestra: state.gestoreUtente.palestraassociata.palestra,
		email: state.gestoreUtente.emailloggato,
		token: state.gestoreUtente.token,
	}
}

export default connect(mapStateToProps, null)(Tipologie)