import React, { useState } from "react";
import SelectTipoMovimento from '../tipologie/selectTipologieMovimento'
import '../util/config'
import { useDispatch, useSelector } from "react-redux";
import { cercaProtocolli } from "../DAO/protocolloDAO";
import {navigate} from 'hookrouter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons'
import { setprotocollicercati } from "../STORE/reducers/gestoreProtocolli";

export default function RicercaProtocolli (props) {
  const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
  const email = useSelector(state => state.gestoreUtente.emailloggato);
	const token = useSelector(state => state.gestoreUtente.token);

  const dispatch = useDispatch();

  const [tipo, settipo] = useState('');
  const [descrizione, setdescrizione] = useState('');
  const [idtipomovimento, setidtipomovimento] = useState('');
  const [oggetto, setoggetto] = useState(''); 
  const [datascadenza, setdatascadenza] = useState('');
  const [dataarrivo, setdataarrivo] = useState('');
  const [datainserimento, setdatainserimento] = useState('');
      
  function handleSubmit(event) {
        async function cercap(email, idpalestra, descrizione, tipo, idtipo, 
          oggetto, datascd, dataarr, datains, tkn){
            try{
          const result = await cercaProtocolli(email, idpalestra, descrizione, tipo, idtipo, 
            oggetto, datascd, dataarr, datains, tkn);
            dispatch(setprotocollicercati(result));
          }
          catch(error){
            console.log(error);
              if (error && error.response && error.response.data){
                alert('Errore',error.response.data.message)
              }
              else{
                //console.log(error.message);
              }
            }
        }
        if (palestra){
          event.preventDefault();
          cercap(email, palestra.id,
            descrizione, tipo, idtipomovimento,
            oggetto, datascadenza, dataarrivo, datainserimento,
            token);
        }
  }

        return (
        <form style={{"border" : "2px solid #FFF","margin" : "5px"}}>
          <div id="pippolo" className="container">
          <div className="row">
          	<h4>Ricerca protocolli</h4>
            <button className="btn" onClick={() => navigate('/annotatio-proto-ins/')}>
              <FontAwesomeIcon icon={faPlusCircle} /> Nuovo Protocollo
            </button>
          </div>
				<div className="row">
					<div className="form-group">
						<label>Data arrivo</label>
						<input className="form-control" name="dataarrivo" type="date" value={dataarrivo} onChange={(e) => setdataarrivo(e.target.value)} />
					</div>
          <div className="form-group">
							<label>Data scadenza</label>
							<input className="form-control" name="datascadenza" type="date" value={datascadenza} onChange={(e) => setdatascadenza(e.target.value)} />
						</div>
						<div className="form-group">
							<label>Entrata/Uscita</label>
							<select className="form-control" value={tipo} name="tipo" onChange={(e) => settipo(e.target.value)}>
								<option value="">--tipo protocollo--</option>
								<option value="E">Entrata</option>
								<option value="U">Uscita</option>
							</select>
						</div>
            </div><div className="row">
						<div className="form-group">
							<label>Oggetto</label>
							<input className="form-control" maxLength="50" name="oggetto" type="text" value={oggetto} onChange={(e) => setoggetto(e.target.value)} />
						</div>
						<div className="form-group">
							<label>Descrizione</label>
							<textarea className="form-control" name="descrizione" type="text" value={descrizione} onChange={(e) => setdescrizione(e.target.value)}></textarea>
						</div>
						<div className="form-group">
							<SelectTipoMovimento updateTipo={setidtipomovimento} selected={idtipomovimento} />
						</div>
						
              <div className="form-group">
					        <a type="button" className="btn btn-success" onClick={handleSubmit} >Cerca</a>
					    </div>
                </div>
                </div>
            </form>
      );
  }