import React from "react";
import {navigate} from 'hookrouter'
import '../util/config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEdit} from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from "react-redux";
import { setprotocolloselezionato } from "../STORE/reducers/gestoreProtocolli";


export default function Protocollo (props) {

	const dispatch= useDispatch()

	    return (
			<tr>
				<td>
					<a type="button" className="btn btn-info btn-lg" onClick={() => {dispatch(setprotocolloselezionato(props.selected));navigate('/annotatio-proto-dett/')}}>
					<FontAwesomeIcon icon={faEdit} />
					</a>
				</td>
				<td>
				{props.selected.numero}/{props.selected.anno}
				</td>
				<td>
						{props.selected.dataarrivo}
				</td>
				<td>
						{props.selected.tipo}
				</td>
				<td>
						 {props.selected.oggetto}
				</td>
				<td>
						{props.selected.idtipomovimento}
				</td>
				<td>
						{props.selected.datascadenza}
				</td>
				<td className="col-1">
				{props.selected.files&&Array.isArray(props.selected.files)?
					(props.selected.files.map(item =>(
							<a key={item.id} href={global.config.server+"/scaricaFile/"+item.id}>Allegato ({item.dimensione}Kb)<br /></a>
					)
					)
					)
				
				:""
				}
				</td>
				</tr>
	    );
}
