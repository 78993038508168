import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAnagraficaByMailAndPalestra, insAnagrafica } from "../DAO/anagraficaDAO";
import { setanagrafica, setEmailLoggato, setPalestraAnagrafica, setpalestraassociata, setpalestreassociate, settoken } from "../STORE/reducers/gestoreUtente";
import { getPalestraFromUrl, getPalestraSocioFromUrlEmail } from "./globals";
import { navigate } from 'hookrouter'
import { getpalestreassociate } from "../DAO/palestraDAO";
import gettoken from "../DAO/tokenDAO";

export default function LoadMainThings(props){
    const palestraanagrafica = useSelector(state => state.gestoreUtente.palestraAnagrafica);
    const emailloggato = useSelector(state => state.gestoreUtente.emailloggato);
    const palestrasocio = useSelector(state => state.gestoreUtente.palestraassociata);
    const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);
    const idanagselezionata = useSelector(state => state.gestoreUtente.idanagselezionata);
    const anagselezionata = useSelector(state => state.gestoreUtente.anagselezionata);
    const token = useSelector(state => state.gestoreUtente.token);

    //const [carrello, setcarrello] = useState([]);
    const dispatch=useDispatch();

    function caricaEmail(){
        let emailtemp=""
        if (props.email){
            emailtemp=props.email
        }
        else if (localStorage.getItem("email")){
            emailtemp=localStorage.getItem("email");
        }
        if (emailtemp&&!emailloggato){
            dispatch(setEmailLoggato(props.email));
        }
    }

    function gettokenannotatio(){
        async function gett(){
            console.log('gettokenannotatio')
            if (emailloggato&&!token){
                console.log('emailloggato&&!token')
                const result = await gettoken(emailloggato,token)
                window.$t=result
                dispatch(settoken(result))
            }
            else{
                console.log('emailloggato&&!token FALSE')
            }
        }
        if(!props.t){
            gett();
        }
        else{
            dispatch(settoken(props.t))
        }
    }

    //se il sito e' del cliente (es. physicolab.it) allora ritorna una sola palestra-socio
    //se il sito e' annotatio allora ritorna solo  quella che e' stata selezionata dalla lista delle palestre associate
    function cariacPalestraSocio(){
        console.log("caricapalestrasocio")
        async function carica()
        {
            let emailtemp=""
            if (props.email){
                emailtemp=props.email
            }
            else if (localStorage.getItem("email")){
                emailtemp=localStorage.getItem("email");
            }
            if (emailtemp&&(!palestrasocio||!palestrasocio.id)){
                const palestreassociate=await getpalestreassociate(emailtemp);
                if(palestreassociate&&Array.isArray(palestreassociate)&&palestreassociate.length>0){
                    dispatch(setpalestreassociate(palestreassociate))
                    const paltemp=await getPalestraSocioFromUrlEmail(palestreassociate, token);
                    
                    dispatch(setpalestraassociata(paltemp))
                }
            }
        }
        carica()
    }

    function caricaPalestraAnagrafica(){
        console.log("caricaPalestraAnagrafica")
        async function carica()
        {
            if (!palestraanagrafica||!palestraanagrafica.id){
                const paltemp=await getPalestraFromUrl(token);
                dispatch(setPalestraAnagrafica(paltemp))
            }
        }
        carica()
    }

    function caricaAnagrafica(){
        console.log("caricaAnagrafica")
        async function carica()
        {
            let emailtemp=""
            if (props.email){
                emailtemp=props.email
            }
            else if (localStorage.getItem("email")){
                emailtemp=localStorage.getItem("email");
            }
            console.log("carico anagrafica")
            if ((emailloggato||emailtemp)&&(!anagrafica||!anagrafica.id)&&palestraanagrafica&&palestraanagrafica.id){
                console.log("email login recuperata "+emailloggato+"-"+emailtemp+" e palestra recuperata "+palestraanagrafica.id)
                const anagtemp=await getAnagraficaByMailAndPalestra(emailtemp?emailtemp:emailloggato, palestraanagrafica.id,'000');
                if ((!anagtemp||!anagtemp.id)){
                        console.log("anagtemp non trovata")
                        const anagr={id:0, nome: props.nome?props.nome:"", 
                        cognome: props.cognome?props.cognome:"", 
                        telefono: props.telefono?props.telefono:"", email: emailloggato?emailloggato:emailtemp, palestra:palestraanagrafica.id}
                        console.log("provo a creare anagrafica con "+props.nome+" "+props.cognome+" "+props.telefono)
                        const result=await insAnagrafica(emailloggato,palestraanagrafica.id,anagr)
                        dispatch(setanagrafica(result))
                        return;
                }
                else{
                    console.log("trovata anagrafica "+anagtemp.id)
                    console.log(anagtemp)
                }
                dispatch(setanagrafica(anagtemp))
            }
            else{
                console.log("niente login email "+emailloggato+"-"+emailtemp+" e niente palestra "+palestraanagrafica.id)
            }
        }
        carica()
    }

    useEffect(() => {
        console.log('asd');
        if (!props.t){
            gettokenannotatio()
        }
        else if (props.t&&!token){
            dispatch(settoken(props.t))
        }
        if(token){
            caricaEmail()
            caricaPalestraAnagrafica();
            caricaAnagrafica();
        }
        cariacPalestraSocio();
        
        
    },[palestrasocio, emailloggato, token])
    //data can be stored in a variable
    const headingText = "heading";

    //data can be stored in a object
    const myObj = {
      heading: 'heading',
      des: 'description',
      linkText: 'Read More',
      linkUrl: 'https://www.npmjs.com/package/react-smart-card'
    };

    return (
        <div>
            <span>
            
                {
                props.showDebugInfo &&
                <div> anag selez {idanagselezionata} -
                    {emailloggato} - {palestraanagrafica.palestra} - {anagrafica&&anagrafica.id?anagrafica.nome:''} {palestraanagrafica.website} {props.nome}
                        Socio di {palestrasocio.id} {palestrasocio.palestra?palestrasocio.palestra.palestra:""} {palestrasocio.palestra&&palestrasocio.palestra.amministratore?"ADMIN":""}
                </div>
                }
            </span>
            {
                idanagselezionata && idanagselezionata>0 ?
                <div>
                    {anagselezionata.nome} {anagselezionata.cognome} &nbsp;
                    {palestrasocio&&(palestrasocio.amministratore||(palestrasocio.funzionalita&&palestrasocio.funzionalita.indexOf('CALENDARIOEPRENOTAZIONI')>=0))&&
                    <>
                        <button className={"btn btn-sm btn-outline-dark"} type="button" onClick={()=>navigate('/anntt-adm-dettanag/')}>Anagrafica</button>
                        
                        <button className={"btn btn-sm btn-outline-dark"} type="button" onClick={()=>navigate('/anntt-adm-abbs/')}>Abbonamento</button>
                    </>
                    }
                    
                    {palestrasocio&&((palestrasocio.funzionalita&&palestrasocio.funzionalita.indexOf('DOCUMENTI')>=0))&&
                <button className={"btn btn-sm btn-outline-dark"} type="button" onClick={()=>navigate('/anntt-adm-docs/')}>Documenti</button>
                }
                {palestrasocio&&((palestrasocio.funzionalita&&palestrasocio.funzionalita.indexOf('PIANOALIMENTARE')>=0))&&
                <button className={"btn btn-sm btn-outline-dark"} type="button" onClick={()=>navigate('/anntt-adm-dettcc/')}>Scheda Comp. Corp.</button>
                }
                

                {palestrasocio&&(palestrasocio.amministratore||(palestrasocio.funzionalita&&palestrasocio.funzionalita.indexOf('CALENDARIOEPRENOTAZIONI')>=0))&&
                <button className={"btn btn-sm btn-outline-dark"} type="button" onClick={()=>navigate('/anntt-adm-preno/')}>Prenotazioni</button>
                }
                {palestrasocio&&((palestrasocio.funzionalita&&palestrasocio.funzionalita.indexOf('CONTROLLOACCESSI')>=0))&&
                <button className={"btn btn-sm btn-outline-dark"} type="button" onClick={()=>navigate('/anntt-adm-ingrs/')}>Accessi</button>
                }
                {palestrasocio&&(palestrasocio.amministratore||(palestrasocio.funzionalita&&palestrasocio.funzionalita.indexOf('MESSAGGI')>=0))&&
                <button className={"btn btn-sm btn-outline-dark"} type="button" onClick={()=>navigate('/anntt-adm-msg/')}>Messaggi</button>
                }
                {palestrasocio&&((palestrasocio.funzionalita&&palestrasocio.funzionalita.indexOf('TESSERAMENTI')>=0))&&
                <button className={"btn btn-sm btn-outline-dark"} type="button" onClick={()=>navigate('/anntt-adm-tess/')}>Tesseramenti</button>
                }
            </div>
            :""
            }
        </div>
    );
}