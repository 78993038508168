import React from "react";
import { useEffect } from "react";
import { getProtoById } from "../DAO/protocolloDAO";
import { setprotocolloselezionato } from "../STORE/reducers/gestoreProtocolli";
import { useSelector } from "react-redux";
import DettaglioProtocollo from "./dettaglioProtocollo";

export default function ProtoFromUrl(){

    const email = useSelector(state => state.gestoreUtente.emailloggato);
	const token = useSelector(state => state.gestoreUtente.token);
    
    function getProto(id){
        async function getP(){
            const result = await getProtoById(email,id,token)
            setprotocolloselezionato(result)
        }
        getP()
    }

    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        console.log("parammm "+params.get('id'));
        getProto(params.get('id'))

    },[])

    return(
        <div>
            <DettaglioProtocollo />
        </div>
    )
}