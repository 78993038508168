import React from "react";
import axios from 'axios';
import '../util/config'
import { connect } from "react-redux";
import serverInfo from "../util/globals";

class SelectTipoMovimento extends React.Component {
	 constructor(props) {
		    super(props);
		    this.state = {
		    		listaTipo:[],
		    		selezionato:this.props.selected
		    		};
		    this.aggiornaSelezionato = this.aggiornaSelezionato.bind(this);
		  }
	 
	 componentDidMount(){
		if (this.props.palestra&&this.props.palestra.palestra){
			console.log(this.props.palestra)
		const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email',this.props.email);
		formData.append('palestra',this.props.palestra.palestra.id);
		formData.append('lic','000');
		  axios.post(serverInfo.namesec+"/conto/getTipologieWp",formData,
		  {
		  headers: {
			Accept: 'application/json',
			'Content-Type': 'multipart/form-data',
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'POST',
			'Access-Control-Allow-Headers': 'Content-Type, Authorization',
			'Access-Control-Allow-Credentials': 'true',
			'Authorization' :'Bearer '+this.props.token
		  },
		  },)
		  .then(response=>{
			console.log("tipologie: ")
			  console.log(response.data)
			  this.setState({listaTipo: response.data});
		  })
		  .catch(err => {
			alert("lista tipologie: "+err.response.data.message);
		});
	}
	  }

	 aggiornaSelezionato(e){
		 if (this.state.listaTipo[e.target.selectedIndex-1]){
		 	this.props.updateTipo(this.state.listaTipo[e.target.selectedIndex-1].codice);
		 }
		 else{
			this.props.updateTipo(''); 
		 }
	 }

	render() {
	    return (
	    	<div className="col">
			<label className="control-label">Tipologia</label>
			<select className="form-control" onChange={(e) =>this.aggiornaSelezionato(e)} name="tipo" value={this.props.selected?this.props.selected:""}>
				<option key={-1} value="">-- nessun tipo --</option>
				{
					this.state.listaTipo?
					this.state.listaTipo.map((item,index) =>(
					<option key={index} value={item.codice} >{item.descrizione}</option>
					)
					)
					:null
	    		}
			</select>
			</div>
	    );
	  }
	}

	const mapStateToProps = state => {
		return {
			palestra: state.gestoreUtente.palestraassociata,
			email: state.gestoreUtente.emailloggato,
			token: state.gestoreUtente.token
		}
	}
	
	export default connect(mapStateToProps, null)(SelectTipoMovimento)