import React from "react";
import axios from 'axios';
import Swal from "sweetalert2";
import '../util/config'
import { connect } from "react-redux";

class Tipologia extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
				id: this.props.selected.id,
	    		codice: this.props.selected.codice,
	    		descrizione: this.props.selected.descrizione,
	    		entrataUscita: this.props.selected.entrataUscita,
				previsioni:this.props.selected.previsioni,
				totale:this.props.selected.totale,
	    		visible: false
	    	}

	    this.update = this.update.bind(this);
	    this.elimina = this.elimina.bind(this);
	    this.handleSubmit = this.handleSubmit.bind(this);
	  }

	update(e) {
		  this.setState({ [e.target.name]: e.target.value, visible: true });
	  }

	handleSubmit(event) {
		  event.preventDefault();
	    const {id, codice,descrizione,entrataUscita,previsioni,totale } = this.state;
		const formData = new FormData();
		formData.append("id",id)
	    formData.append('codice', codice);
	    formData.append('descrizione',descrizione);
		formData.append('entrataUscita', entrataUscita);
		formData.append('previsioni', previsioni);
		formData.append('totale', totale);
		formData.append('email',this.props.email);
		formData.append('palestra',this.props.palestra.id);
		formData.append('lic','000');
		formData.append('website', global.config.domain);
    axios.post(global.config.server+'/salvaTipo/'+codice, 
    		formData
    )
      .then((result) => {
      this.setState({messaggio:result.data});
		const Toast = Swal.mixin({
			  toast: true,
			  position: 'top-end',
			  showConfirmButton: false,
			  timer: 3000
			});
			Toast.fire({
			  type: 'success',
			  title: result.data
			})
        this.props.refresh();
        this.setState({ visible: false });
      });
  }

	  elimina() {
		  if (window.confirm('Sei sicuro di voler cancellare la tipologia selezionata?')){
			const formData = new FormData();
			formData.append('email',this.props.email);
		formData.append('palestra',this.props.palestra.id);
		formData.append('lic','000');
			formData.append('website', global.config.domain);
			  axios.post(global.config.server+'/eliminaTipoMovimento/'+this.state.id, formData)
			        .then((result) => {
				         const Toast = Swal.mixin({
						  toast: true,
						  position: 'top-end',
						  showConfirmButton: false,
						  timer: 3000
						});
						Toast.fire({
						  type: 'success',
						  title: result.data
						})
			        this.props.refresh();
			          this.setState({ visible: false });
			        });
			}
	  }

	render() {
		var style1={
				color:"white"
		}
		
	    return (
	    	<form method="POST">
	    		<div className="row">
					<div className="col">
						<label>Codice</label>
						<input name="codice" type="text" maxLength="50" value={this.state.codice} readOnly={true} />
					</div>
					<div className="col">	
						<label>Descrizione</label>
						<input maxLength="50" name="descrizione" type="text" value={this.state.descrizione} onChange={this.update} />
					</div>
					<div className="col">	
						<label>Entrata/Uscita</label>
						<select name="entrataUscita" onChange={this.update} value={this.state.entrataUscita}>
							<option value="E">Entrata</option>
							<option value="U">Uscita</option>
							<option value="EU">Entrata e Uscita</option>
						</select>
					</div>
					<div className="col">
						<a className="btn btn-danger" style={style1} onClick={this.elimina}>Elimina</a>
					</div>
						{this.state.visible?
							<div className="col">
			  				<a type="button" className="btn btn-primary" onClick={this.handleSubmit} style={style1}>Salva modifiche</a>
			  				</div>
			  			:null}
				</div>
					<hr />
				</form>
	    );
	  }
	}

	const mapStateToProps = state => {
		return {
			palestra: state.gestoreUtente.palestraassociata.palestra,
			email: state.gestoreUtente.emailloggato,
			token: state.gestoreUtente.token
		}
	}
	
	export default connect(mapStateToProps, null)(Tipologia)