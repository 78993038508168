import axios from 'axios';
import serverInfo from '../util/globals';

export async function cercaProtocolli(email, palestra, descrizione, tipo, idtipomovimento, oggetto, 
    datascadenza, dataarrivo, datainserimento,token) {
    const formData = new FormData();
    formData.append('descrizione',descrizione);
    formData.append('tipo', tipo);
    formData.append('idtipomovimento', idtipomovimento);
    formData.append('oggetto', oggetto);
    formData.append('datascadenza', datascadenza);
    formData.append('dataarrivo', dataarrivo);
    formData.append('datainserimento', datainserimento);
    formData.append('website', global.config.domain);
    formData.append('email',email);
    formData.append('palestra',palestra);
    formData.append('lic','000');

  const response = await axios.post(
    serverInfo.namesec+'/proto/cercaProtocolli',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+token
      },
    },
  );
  console.log(response);
  return response.data;
}

export async function getProtoById(email, idproto,token) {
  const formData = new FormData();
  formData.append('idproto',idproto);
  formData.append('email',email);

const response = await axios.post(
  serverInfo.namesec+'/proto/getProtoById',
  formData,
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials': 'true',
      'Authorization' :'Bearer '+token
    },
  },
);
console.log(response);
return response.data;
}

export async function inviaemailproto(email, palestra, idproto, token) {
  const formData = new FormData();
  formData.append('id',idproto);
  formData.append('email',email);
  formData.append('palestra',palestra);

const response = await axios.post(
  serverInfo.namesec+'/proto/email',
  formData,
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials': 'true',
      'Authorization' :'Bearer '+token
    },
  },
);
console.log(response);
return response.data;
}